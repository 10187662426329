import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private messageSource = new BehaviorSubject(false);
  currentMessage = this.messageSource.asObservable();

  private objectReport = new BehaviorSubject({});
  getobjectReport = this.objectReport.asObservable();

  private objectBack = new BehaviorSubject({});
  getobjectBack = this.objectBack.asObservable();

  private notificationDropdown = new BehaviorSubject({});
  getnotificationDropdown = this.notificationDropdown.asObservable();

  private resolutionData = new BehaviorSubject({});
  getresolution = this.resolutionData.asObservable();

  private isLogin = new BehaviorSubject(true);
  getIsLogin = this.isLogin.asObservable();

  private url = new BehaviorSubject(null);
  getUrl = this.url.asObservable();

  private questionId = new BehaviorSubject(null);
  getQuestionId = this.questionId.asObservable();

  notificationObject = {
    notifications: [],
    totalRecords: 0,
  }

  resolutionObject = {
    type: 0,
    id: 0,
  }

  constructor() { }

  changeMessage(data: any) {
    this.messageSource.next(data);
  }

  setobjectsearch(data: any) {
    this.objectReport.next(data);
  }

  setobjectBack(data: any) {
    this.objectBack.next(data);
  }

  setresolutionData(data: any) {
    this.resolutionObject.type = data.type;
    this.resolutionObject.id = data.id;
    this.resolutionData.next(this.resolutionObject);
  }

  setIsLogin(data) {
    this.isLogin.next(data);
  }

  setUrl(url) {
    if (url != this.url.value) {
      this.url.next(url);
    }
  }

  setQuestionId(id) {
    this.questionId.next(id);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceInvokerService } from '../commons/service-invoker.service';
import { Observable } from 'rxjs';
import { AppSettings } from '../constants/app-setting';
import { Api } from '../constants/api';
import { DepartmentObject } from '../models/departmentObject';
import { UserInfoStorageService } from '../commons/user-info-storage.service';
import { Router } from '@angular/router';
import { DeviceDetectorService  } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class TrangChuService {

  constructor(private http: HttpClient,
    private serviceInvoker: ServiceInvokerService,
    private localStronageService: UserInfoStorageService,
    private storeageService: UserInfoStorageService,
    private router: Router,
    private deviceService: DeviceDetectorService) {
      this.epicFunction();
      var Ip =  this.storeageService.getIpAddress();
      if(Ip == "" || Ip == null || Ip == undefined)
      {
        this.http
        .get<{ ip: string }>("https://jsonip.com/")
        .subscribe((data) => {
          if (data != null) {
            this.storeageService.setIpAddress(data.ip);
          }
        });
      }
     }

    thietBi :string ="";
    os: string= "";
    trinhDuyet : string= "";
    isDesktop: boolean = false;
    isMobile: boolean = false;
    isTablet : boolean = false;
    epicFunction() {
      
      this.isDesktop = this.deviceService.isDesktop();
      this.isMobile = this.deviceService.isMobile();
      this.isTablet = this.deviceService.isTablet();
  
      if(this.isDesktop)
      {
        this.thietBi = "Desktop";
      }
      if(this.isMobile)
      {
        this.thietBi = "Mobile";
      }
      if(this.isTablet)
      {
        this.thietBi = "Tablet";
      }
      this.os  = this.deviceService.getDeviceInfo().os + '/' + this.deviceService.getDeviceInfo().os_version;
    }
  


  GetDropDownBanner(request): Observable<any> {
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.DropDownBanner);
  }
  GetDropDownTinHot(request): Observable<any> {
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.DropDownTinHot);
  }
  DropDownTinHotQuangCao(request): Observable<any> {
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.DropDownTinHotQuangCao);
  }
  GetListSearch(request): Observable<any> {
    request.UrlClient = this.router.url;
    request.Os = this.os;
    request.ThietBi = this.thietBi;
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.GetListSearch);
  }
  GetListSearchLoc(request): Observable<any> {
    request.UrlClient = this.router.url;
    request.Os = this.os;
    request.ThietBi = this.thietBi;
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.GetListSearchLoc);
  }
  GetListLuuTru(request): Observable<any> {
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.GetListLuuTru);
  }
  GetListThuMuc(request): Observable<any> {
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.GetListThuMuc);
  }
  GetDetail(request): Observable<any> {
    request.UrlClient = this.router.url;
    request.Os = this.os;
    request.ThietBi = this.thietBi;
    request.IpAddress = this.storeageService.getIpAddress();

    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.GetDetail);
  }
  GetRecomment(request): Observable<any> {
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.GetRecomment);
  }
  getSuggest(request): Observable<any> {
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.GetRecomment);
  }
  DanhSachNhaLuuTru(request): Observable<any> {
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.DanhSachNhaLuuTru);
  }

  GetlistSearch(): Observable<any> {
    return this.serviceInvoker.post(null, AppSettings.API_ADDRESS + Api.ListSearch);
  }
  GetlistSearchCaNhan(request): Observable<any> {
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.ListSearchCaNhan);
  }
  GetlistPhuongSearch(id): Observable<any> {
    return this.serviceInvoker.post(id, AppSettings.API_ADDRESS + Api.GetlistPhuongSearch);
  }
  GetlistPhuongDetailSearch(id): Observable<any> {
    return this.serviceInvoker.post(id, AppSettings.API_ADDRESS + Api.GetlistPhuongDetail);
  }
  GetLikeById(request): Observable<any> {
    request.UrlClient = this.router.url;
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.DanhSachNhaLike);
  }
  GetUnLikeById(request): Observable<any> {
    request.UrlClient = this.router.url;
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.DanhSachNhaUnLike);
  }
  GetCommentById(id): Observable<any> {
    
    return this.serviceInvoker.post(id,AppSettings.API_ADDRESS + Api.DanhSachNhaListCommentById);
  }
  GetThaoLuanById(id): Observable<any> {
    return this.serviceInvoker.post(id,AppSettings.API_ADDRESS + Api.DanhSachNhaListThaoLuanById);
  }
  GetDanhSachNgoCommentById(id): Observable<any> {
    return this.serviceInvoker.post(id,AppSettings.API_ADDRESS + Api.GetDanhSachNgoCommentById);
  }
  OnComment(id): Observable<any> {
    id.UrlClient = this.router.url;
    id.Os = this.os;
    id.ThietBi = this.thietBi;
    id.IpAddress = this.storeageService.getIpAddress();
    return this.serviceInvoker.post(id,AppSettings.API_ADDRESS + Api.DanhSachNhaCreateComment);
  }
  OnThaoLuan(id): Observable<any> {
    id.UrlClient = this.router.url;
    id.Os = this.os;
    id.ThietBi = this.thietBi;
    id.IpAddress = this.storeageService.getIpAddress();
    return this.serviceInvoker.post(id,AppSettings.API_ADDRESS + Api.DanhSachNhaCreateThaoLuan);
  }
  OnNgo(id): Observable<any> {
    id.UrlClient = this.router.url;
    return this.serviceInvoker.post(id,AppSettings.API_ADDRESS + Api.DanhSachNhaCreateCommentNgo);
  }
  OnThuMuc(id): Observable<any> {
    return this.serviceInvoker.post(id,AppSettings.API_ADDRESS + Api.DanhSachNhaCreateThuMuc);
  }
  GetListKhaoSatNgo(id): Observable<any> {
    return this.serviceInvoker.post(id,AppSettings.API_ADDRESS + Api.GetListKhaoSatNgo);
  }
  GetDropDownThuMuc(req): Observable<any> {
    //debugger
    return this.serviceInvoker.post(req, AppSettings.API_ADDRESS + Api.GET_TrangChu_DROPDOWNThuMuc);
  }
  GetListSaved(id): Observable<any> {
    return this.serviceInvoker.post(id,AppSettings.API_ADDRESS + Api.GetListSaved);
  }
  OnShare(id): Observable<any> {
    id.UrlClient = this.router.url;
    id.Os = this.os;
    id.ThietBi = this.thietBi;
    id.IpAddress = this.storeageService.getIpAddress();
    return this.serviceInvoker.post(id,AppSettings.API_ADDRESS + Api.DanhSachNhaLogShare);
  }
  CheckOtp(request): Observable<any> {
    
    return this.serviceInvoker.post(request,AppSettings.API_ADDRESS + Api.CheckOtp);
  }
  CheckCanhBao(request): Observable<any> {
    
    return this.serviceInvoker.post(request,AppSettings.API_ADDRESS + Api.CheckCanhBao);
  }
  DeleteCanhBao(request): Observable<any> {
    
    return this.serviceInvoker.post(request,AppSettings.API_ADDRESS + Api.DeleteCanhBao);
  }
  DeleteCanhBaoLoai(request): Observable<any> {
    
    return this.serviceInvoker.post(request,AppSettings.API_ADDRESS + Api.DeleteCanhBaoLoai);
  }
  DeleteOtp(request): Observable<any> {
    
    return this.serviceInvoker.post(request,AppSettings.API_ADDRESS + Api.DeleteOtp);
  }
  getNccNguoiDungByPhone(request: any): Observable<any> {
    return this.serviceInvoker.post(
      request,
      AppSettings.API_ADDRESS + Api.TrangChu_GetNCCNGUOIDUNGByPhone
    );
  }
  registerUser(data: any): Observable<any> {
    const uploadData = new FormData();
    data.User.UserId = this.localStronageService.getUserId();
    data.User.AccountId = this.localStronageService.getAccountId();
    data.User.IpAddress = this.localStronageService.getIpAddress();
    data.User.UrlClient = this.router.url;

    if (data.listFile != null) {
      for (var i = 0; i < data.listFile.length; i++) {
        uploadData.append(
          "DinhKemUser" + i,
          data.listFile[i],
          data.listFile[i].name
        );
      }
    }
    uploadData.append("User", JSON.stringify(data.User));
    uploadData.append("NguoiDung", JSON.stringify(data.NguoiDung));
   
    console.log(uploadData);
    return this.http.post(
      AppSettings.API_ADDRESS + Api.RegisterUser,
      uploadData
    );
  }

  searhNhaByThongSo(request: any): Observable<any> {
    return this.serviceInvoker.post(
      request,
      AppSettings.API_ADDRESS + Api.TrangChu_SearhNhaByThongSo
    );
  }
  DeleteLuuTru(request): Observable<any> {
    return this.serviceInvoker.post(request,AppSettings.API_ADDRESS + Api.DeleteLuuTru);
  }
  DeleteThuMuc(request): Observable<any> {
    return this.serviceInvoker.post(request,AppSettings.API_ADDRESS + Api.DeleteThuMuc);
  }
  DeleteLuuTruAll(request): Observable<any> {
    return this.serviceInvoker.post(request,AppSettings.API_ADDRESS + Api.LuuTruDeleteAll);
  }
  GetlistDuongPho(request): Observable<any> {
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.GetlistDuongPho);
  }
  GetDropDownTrangChu(request): Observable<any> {
    request.UrlClient = this.router.url;
    request.Os = this.os;
    request.ThietBi = this.thietBi;
    request.IpAddress = this.storeageService.getIpAddress();
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.DropDownTrangChu);
  }
  GetDropDownTrangChuQuangCao(request): Observable<any> {
    request.UrlClient = this.router.url;
    request.Os = this.os;
    request.ThietBi = this.thietBi;
    request.IpAddress = this.storeageService.getIpAddress();
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.GetDropDownTrangChuQuangCao);
  }
  GetTuyenPhoById(request): Observable<any> {
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.TrangChuTuyenPhoGetById);
  }
  GetlistDuongPhoPhanKhuc(request): Observable<any> {
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.GetlistDuongPhoPhanKhuc);
  }

  SendOTP(data: any): Observable<any> {
    return this.serviceInvoker.post(data,AppSettings.API_ADDRESS + Api.SendOTP);
  }
  NgoTemplateGetDropDown(request): Observable<any> {
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.DropDownNgoTemplate);
  }

  GetListNew(request): Observable<any> {
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.TrangChuGetListNew);
  }
  GetCountNew(request): Observable<any> {
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.TrangChuGetCountNew);
  }
  GetListNccNguoiDungByLoai(request): Observable<any> {
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.TrangChuGetListNccNguoiDungByLoai);
  }
  LockUser(request): Observable<any> {
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.TrangChuLockUser);
  }
  LogXemThongSo(request): Observable<any> {
    request.UrlClient = this.router.url;
    request.Os = this.os;
    request.ThietBi = this.thietBi;
    request.IpAddress = this.storeageService.getIpAddress();
    return this.serviceInvoker.post(request,AppSettings.API_ADDRESS + Api.TrangChuLogXemThongSo);
  }
  TrangChuGetAllSystemLogByChuyenVien(request): Observable<any> {
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.TrangChuGetAllSystemLogByChuyenVien);
  }
  TrangChuGetNccNguoiDungThongTinByChuyenVienDropDown(request): Observable<any> {
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.TrangChuGetNccNguoiDungThongTinByChuyenVienDropDown);
  }
}

import {
  Component,
  OnInit,
  AfterViewChecked,
  ChangeDetectorRef,
} from "@angular/core";
import { UserInfoStorageService } from "./commons/user-info-storage.service";
import { Router, RouterStateSnapshot } from "@angular/router";
import { environment } from "../environments/environment";
import { DeviceDetectorService } from "ngx-device-detector";
import { AppSettings } from "./constants/app-setting";
declare var jquery: any;
declare var $: any;

import { Store } from '@ngrx/store';
import { HubConnection } from '@aspnet/signalr';
import * as signalR from '@aspnet/signalr';
import { TrangChuService } from "./services/trangchu.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, AfterViewChecked {
  env = environment;
  showLoader: boolean;
  isLogin: boolean;
  state: RouterStateSnapshot;

  isDesktop: boolean = false;
  isMobile: boolean = false;
  isTablet : boolean = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    private storeageService: UserInfoStorageService,
    private _router: Router,
    private deviceService: DeviceDetectorService,
    private store: Store<any>,
    private trangchuService: TrangChuService
  ) {
    var check = this.storeageService.getAccessToken();
    if (check == "" || check == null || check == undefined) {
      this.isLogin = false;
    } else {
      this.isLogin = true;
    }
    var currentlink = location.toString();
    var returnlURL = this.storeageService.getReturnUrl();
    if ((returnlURL == undefined || returnlURL == '' || returnlURL == null || returnlURL == 'undefined') && !currentlink.includes("login")) {
      this.storeageService.setReturnUrl(location.pathname);
    }
    //mobile
    this.isMobile = this.deviceService.isMobile();
    //debugger
    if( this.isMobile)
    {
      var pathLink = location.pathname.toString();
      if (currentlink.includes(AppSettings.HostingAddress) || currentlink.includes(AppSettings.HostingAddressNotSecure)) {  
        window.location.href = AppSettings.MobileHostingAddress + pathLink;
        return;
      }
      else
      {
        return;
      }  
    }

  }

  ngOnInit() {
   
    //debugger
    var currentlink = location.toString();
    var pathLink = location.pathname.toString();

    //portal

    // if (currentlink.includes("trang-chu") ||  currentlink.includes("search") ||  currentlink.includes("chitietnha")
    //    ||  currentlink.includes("lich-su-dan-khach") ||  currentlink.includes("them-moi-lich-su-dan-khach") ||  currentlink.includes("kho-luu-tru") ||  currentlink.includes("danh-sach-khach-hang")
    //    ||  currentlink.includes("them-moi-khach-hang") ||  currentlink.includes("cap-nhat-khach-hang") ||  currentlink.includes("danh-sach-nha") 
    //    ||  currentlink.includes("thong-bao") ||  currentlink.includes("dang-ky") ||  currentlink.includes("canh-bao") ||  currentlink.includes("het-han")
    //    ||  currentlink.includes("ban-nha")
    // ) {
    //    return;
    // }

    // if (currentlink.includes("login")) {
    //   this._router.navigate(['/trang-chu']);
    // }

    // var check = this.storeageService.getAccessToken();
    // if (check == "" || check == null || check == undefined) {
    //   this.isLogin = false;
    // } else {
    //   this.isLogin = true;
    // }

    // if (this.isLogin) {

    //   var returnlURL = this.storeageService.getReturnUrl();
    //   if (!returnlURL.includes("business") && returnlURL != undefined && returnlURL != "" && returnlURL != null && returnlURL != "undefined") {
    //      return false;
    //   }
    //   if (currentlink.includes("business") ) {
    //     //debugger
    //     this._router.navigate(["/trang-chu"]);
    //     return;  

    //     // if (!currentlink.includes("mobile")) {
    //     //   this._router.navigate(["/trang-chu"]);
    //     //   return;  
    //     // }
    //     // else
    //     // {
    //     //   this._router.navigate(["/mobile/trang-chu"]);
    //     //   return;  
    //     // }
    //   } else {

    //   }
      
    // } else {

    //   if (pathLink == "" || pathLink == "/" || pathLink.includes("login")) {
    //    this._router.navigate(['/trang-chu']);
    //     return;
    //    }

    //   var returnlURL = this.storeageService.getReturnUrl();
    //   // if ((returnlURL == undefined || returnlURL == "" || returnlURL == null || 
    //   //   returnlURL == "undefined") && (!currentlink.includes("login"))
    //   // ) {      
    //   //   var urlback = this._router.url;
    //   //   this.storeageService.setReturnUrl(urlback);
    //   // }

    //   if (!currentlink.includes("login") && !currentlink.includes("forgotPass") ) {
    //     this._router.navigate(['/trang-chu']);
    //     return;
    //     // if(!currentlink.includes("mobile"))
    //     // {
    //     //    this._router.navigate(['/trang-chu']);
    //     //    return;
    //     // }
    //     // else
    //     // {
    //     //   this._router.navigate(['/mobile/trang-chu']); 
    //     //   return;
    //     // }
    //   }
    // }


    // cms
  //   debugger
   var check = this.storeageService.getAccessToken();
   if (check == "" || check == null || check == undefined) {
     this.isLogin = false;
   } else {
     this.isLogin = true;
   }

   if (this.isLogin) {
     var returnlURL = this.storeageService.getReturnUrl();
    //  if (!returnlURL.includes("trang-chu") && returnlURL != undefined && returnlURL != "" 
    //     && returnlURL != null && returnlURL != "undefined" && (returnlURL.includes("business")) && returnlURL != '/'  ) {
    //     this._router.navigate([returnlURL]);
    //  }
    //  else
    //  {
    //   return;
    //  }

     if (!currentlink.includes("business") ) {
       this._router.navigate(["/business"]);
       return;  

     } else { 
        return;  
     }
     
   } else {

     if (pathLink == "" || pathLink == "/") {
      this._router.navigate(['/login']);
      return;
     }

     var returnlURL = this.storeageService.getReturnUrl();
     if ((returnlURL == undefined || returnlURL == "" || returnlURL == null || 
       returnlURL == "undefined") && (!currentlink.includes("login")) && (!currentlink.includes("trang-chu"))
     ) {      
       var urlback = this._router.url;
       this.storeageService.setReturnUrl(urlback);
     }
     else
      {
        this._router.navigate(["/business"]);
      }

     if (!currentlink.includes("login") && !currentlink.includes("forgotPass") ) {     
         this._router.navigate(['/login']); 
         return;

       }
   }


    $("body").layout("fix");
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }
}

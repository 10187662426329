<div class="limiter container-login100">
  <div class="col-sm-8 bg-login"></div>
  <div class="col-sm-4 flex-box-login">
    <div class="login-container">
      <div class="col-md-12">
        <div class="p-t-70 p-l-20 p-r-20 padding-xs text-center">
          <div class="text-title">
            <img class="img-logo" src="../../../../../assets/dist/img/app-images/logo.png" />
            <h5>Đăng nhập để sử dụng dịch vụ</h5>
          </div>
          <div class="wrap-login100 p-t-30 p-b-50" style="border: inherit">
            <form class="login100-form validate-form flex-sb flex-w" [formGroup]="loginForm" #formDir="ngForm"
              (ngSubmit)="login()">
              <div class="wrap-input100 m-b-16 has-error">
                <input class="input100" placeholder="Tài khoản..." type="text" id="name" formControlName="name"
                  [(ngModel)]="user.UserName" required />
              </div>
              <div *ngIf="name.invalid && (name.dirty || name.touched || submitted)" class="has-error m-b-12">
                <div *ngIf="name.errors.required">
                  <span class="help-block">Trường này không được để trống</span>
                </div>
              </div>

              <div class="wrap-input100 validate-input m-b-16">
                <input class="input100" type="password" id="pass" placeholder="Mật khẩu..." formControlName="pass"
                  [(ngModel)]="user.Password" required />
              </div>
              <div *ngIf="
                  pass.invalid && (pass.dirty || pass.touched || submitted)
                " class="has-error m-b-12">
                <div *ngIf="pass.errors.required">
                  <span class="help-block">Trường này không được để trống</span>
                </div>
              </div>
              <div class="wrap-input100 validate-input input-captcha mb-80">
                <div class="input-group">
                  <div class="input-group-addon no-padding rightauto no-padding">
                    <img (click)="reloadImage()" id="img-captcha" [src]="captchaImage" />
                  </div>
                  <a class="input-group-addon no-padding" (click)="reloadImage()"><i class="fal fa-repeat"></i></a>
                </div>
              </div>
              <div class="wrap-input100 validate-input m-b-16 captcha-code">
                <input #captchainput type="text" class="input100" placeholder="Nhập mã xác thực ở trên"
                  formControlName="captcha" [(ngModel)]="captchaCode" maxlength="5" />
                <div *ngIf="captcha.invalid && (captcha.dirty || captcha.touched)" class="has-error m-b-12">
                  <div *ngIf="captcha.errors.required">
                    <span class="help-block">Vui lòng nhập mã xác thực</span>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right">
                <input style="display: none;" class="pull-left checkbox-login" type="checkbox" formControlName="isRemember"
                  [(ngModel)]="isSaveLogin" />
                <span style="font-size: 16px; margin-left: 5px; float: left; display: none;">Ghi nhớ tài khoản</span>
                <a style="display: none;" (click)="forgetPassWord()">Quên mật khẩu?</a>
              </div>
              <div class="container-login100-form-btn m-t-17" style="margin-top:85px">
                <button class="login100-form-btn" type="submit">
                  ĐĂNG NHẬP
                </button>
                <button style="display: none;" class="btn-back-login mt-2" [routerLink]="['/trang-chu']" routerLinkActive="router-link-active"
                  type="button">
                  <i class="fa fa-arrow-left"></i>&nbsp; QUAY LẠI TRANG CHỦ
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<router-outlet>
  <div *ngIf="env.isContentLoading" class="loading">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

</router-outlet>

import { LocationStrategy } from "@angular/common";
import {
    RouteReuseStrategy,
    ActivatedRouteSnapshot,
    DetachedRouteHandle,
    RouterModule,
    Routes,
    UrlSegment
    } from "@angular/router";
    export class RouteReuseService implements RouteReuseStrategy {

        // // Specify the routes to reuse/cache in an array.
        // routesToCache: string[] = ["trang-chu","search/:idquanhuyen/:idphankhuc/:idphuongxa/:idPho/:textseo"];
        // storedRouteHandles = new Map<string, DetachedRouteHandle>();
        // // Decides if the route should be stored
        // shouldDetach(route: ActivatedRouteSnapshot): boolean {
        //     debugger
        //     var s = this.getPath(route);
        //     return this.routesToCache.indexOf(this.getPath(route)) > -1;
        // }
        // //Store the information for the route we're destructing
        // store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        //     this.storedRouteHandles.set(this.getPath(route), handle);
        // }
        // //Return true if we have a stored route object for the next route
        // shouldAttach(route: ActivatedRouteSnapshot): boolean {
        //     debugger
        //     return this.storedRouteHandles.has(this.getPath(route));
        // }
        // //If we returned true in shouldAttach(), now return the actual route data for restoration
        // retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        //     return this.storedRouteHandles.get(this.getPath(route)) as DetachedRouteHandle;
        // }
        // //Reuse the route if we're going to and from the same route
        // shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        //     if(future.component && (<any>future.component).name == "ShowComponent" ){
        //     return false;
        //     }
        //     return future.routeConfig === curr.routeConfig;
        // }
        // // Helper method to return a path,
        // // since javascript map object returns an object or undefined.
        // private getPath(route: ActivatedRouteSnapshot): string {
        //     let path = "";
        //     if (route.routeConfig != null && route.routeConfig.path != null)
        //     path = route.routeConfig.path;
        //     return path;
        // }

    private routeLeftFrom: string;

    // constructor(location: LocationStrategy) {
    //     location.onPopState(() => {
    //         this.back = true
    //         console.log('Back button pressed!', this.back);
    //     });
    // }

    // // Back-navigation monitoring
    // back: boolean = false

    private handlers: { [key: string]: DetachedRouteHandle } = {};
    shouldDetach(route: ActivatedRouteSnapshot): boolean {

        //debugger

        if (!route.routeConfig || route.routeConfig.loadChildren) {
        return false;
        }
        let shouldReuse = false;
        //console.log("checking if this route should be re used or not", route);
        if (route.routeConfig.data) {
        route.routeConfig.data.reuse ? shouldReuse = true : shouldReuse = false;
        }
        this.routeLeftFrom = route.routeConfig.path;
        return shouldReuse;
    }

    shouldDetach1(route: ActivatedRouteSnapshot): boolean {
        // console.debug('CustomReuseStrategy:shouldDetach', route);
        //debugger
        this.routeLeftFrom = route.routeConfig.path;
        return route.data.reuse || false;
    }

    store(route: ActivatedRouteSnapshot, handler: DetachedRouteHandle): void {
    //console.log("storing handler");
    if (handler) {
    this.handlers[this.getUrl(route)] = handler;
    }
    }
    shouldAttach1(route: ActivatedRouteSnapshot): boolean {
        //console.log("checking if it should be re attached");
        //debugger
        // var s = route.data.reuseRoutesFrom;
        // if(s != null && s != undefined)
        // {
        //     var s1 = route.data.reuseRoutesFrom.indexOf(this.routeLeftFrom)
        // }
        return !!this.handlers[this.getUrl(route)];
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        //debugger

        // const canAttach = !!route.routeConfig;
    
        // if (!canAttach || !this.back)  {
        //    this.back = false
        //    return false;
        // }
    
        // this.back = false


        var wasRoutePreviouslyDetached = !!this.handlers[this.getUrl(route)]; // !!this.handlers[route.url.join('/') || route.parent.url.join('/')];
        //if (wasRoutePreviouslyDetached) {
            var reuseRouteFromVerified = false;
            if(route.data.reuseRoutesFrom != null && route.data.reuseRoutesFrom  != undefined )
            {
                 reuseRouteFromVerified = route.data.reuseRoutesFrom.indexOf(this.routeLeftFrom) > -1;
            }
            if (reuseRouteFromVerified) {

                return true;
            }
        //}
        return wasRoutePreviouslyDetached;

    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    if (!route.routeConfig || route.routeConfig.loadChildren) {
    return null;
    };
    return this.handlers[this.getUrl(route)];
    }
    shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
    let reUseUrl = false;
    if (future.routeConfig) {
    if (future.routeConfig.data) {
    reUseUrl = future.routeConfig.data.reuse;
    }
    }
    const defaultReuse = (future.routeConfig === current.routeConfig);
    return reUseUrl || defaultReuse;
    }
    getUrl(route: ActivatedRouteSnapshot): string {
    if (route.routeConfig) {
    const url = route.routeConfig.path;
    //console.log("returning url", url);
    return url;
    }
    }
}
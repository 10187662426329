import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../constants/app-setting';
import { Api } from '../constants/api';
import { JwtHelperService } from '@auth0/angular-jwt';

const jwtHelper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class UserInfoStorageService {

  userName: string;
  accessToken: string;
  permissions: string;
  userId: string;
  isSaveLogin: boolean;
  isSuperAdmin: boolean;
  isTongHop: boolean;
  deparmentId: number;
  unitId: number;
  accountId: number;
  unitName: string;
  role: number;

  constructor(private http: HttpClient, private _router: Router) { }

  
  checkTokenExpire(_token : any)
  {
    //debugger
    var result = false;

    if (_token == "" || _token == null || _token == undefined) {
      result = true;
    }
    else
    {
      var isExpired = jwtHelper.isTokenExpired(_token);
      if(isExpired == true)
      {
        result = true;
      }
    } 
    return result;
  }

  setAccountId(id: string): void {
    localStorage.setItem('accountId', id);
  }
  setReturnUrl(url: string): void {
    localStorage.setItem('ReturnUrl', url);
  }
  setUserId(id: string): void {
    localStorage.setItem('userId', id);
  }

  setKeyRemember(key: string): void {
    localStorage.setItem('KeyRework', key);
  }

  setUnitName(unitName: string): void {
    localStorage.setItem('unitName', unitName);
  }

  setUserName(name: string): void {
    localStorage.setItem('userName', name);
  }

  setAccessToken(token): void {
    localStorage.setItem('accessToken', token);
  }

  setSaveLogin(isSavelogin: boolean): void {
    localStorage.setItem('isSaveLogin', String(isSavelogin));
  }

  setIsSuperAdmin(isSuperAdmin: boolean): void {
    localStorage.setItem('isSuperAdmin', String(isSuperAdmin));
  }

  setDeparmentId(deparmentId: number): void {
    localStorage.setItem('deparmentId', String(deparmentId));
  }

  setUnitId(unitId: number): void {
    localStorage.setItem('unitId', String(unitId));
  }

  setPermissions(permissions: string): void {
    localStorage.setItem('permissions', permissions);
  }

  setFunctions(functions: string): void {
    localStorage.setItem('functions', functions);
  }

  setPermissionCategories(cate: string): void {
    localStorage.setItem('permissionCategories', cate);
  }

  setIsHaveToken(value: boolean): void {
    localStorage.setItem('IsHaveToken', String(value));
  }

  setIpAddress(value: string): void {
    localStorage.setItem('IpAddress', value);
  }

  setRole(value): void {
    localStorage.setItem('Role', value);
  }

  setFullName(value): void {
    localStorage.setItem('FullName', value);
  }

  getUserId(): string {
    return localStorage.getItem('userId');
  }

  getKeyRemember(): string {
    return localStorage.getItem('KeyRework');
  }

  getUnitName(): string {
    return localStorage.getItem('unitName');
  }

  getReturnUrl(): string {
    return localStorage.getItem('ReturnUrl');
  }

  getUserName(): string {
    return localStorage.getItem('userName');
  }

  getAccessToken(): string {
    return localStorage.getItem('accessToken');
  }

  getSaveLogin(): boolean {
    return localStorage.getItem('isSaveLogin') === "true";
  }

  getIsSuperAdmin(): boolean {
    return localStorage.getItem('isSuperAdmin') === "true";
  }

  getDeparmentId(): number {
    return localStorage.getItem('deparmentId') as any;
  }

  getUnitId(): number {
    return (localStorage.getItem('unitId')) as any;
  }

  getAccountId(): number {
    return (localStorage.getItem('accountId')) as any;
  }

  getPermissions(): string {
    return localStorage.getItem('permissions');
  }

  getFunctions(): string {
    return localStorage.getItem('functions');
  }

  getPermissionCategories(): string {
    return localStorage.getItem('permissionCategories');
  }

  getIsHaveToken(): boolean {
    return (localStorage.getItem('IsHaveToken')) === "true";
  }

  getIpAddress(): string {
    return (localStorage.getItem('IpAddress'));
  }

  getFullName(): string {
    return (localStorage.getItem('FullName'));
  }

  getRole() {
    var role = (localStorage.getItem('Role'));
    if (role == "null")
      return null;
    else
      return role;
  }
  setUserType(id: string): void {
    localStorage.setItem('UserType', id);
  }
  getUserType(): string {
    return localStorage.getItem('UserType');
  }
  setNguoiDungId(id: string): void {
    localStorage.setItem('NguoiDungId', id);
  }
  getNguoiDungId(): string {
    return localStorage.getItem('NguoiDungId');
  }

  clearStoreageAll(): void {
    this.setAccessToken('');
    this.setAccountId('');
    this.setFunctions('');
    this.setIsHaveToken(false);
    this.setIpAddress('');
    this.setIsSuperAdmin(false);
    this.setPermissionCategories('');
    this.setPermissions('');
    this.setSaveLogin(false);
    this.setUnitId(0);
    this.setDeparmentId(0);
    this.setUnitName('');
    this.setUserId('');
    this.setUserName('');
    this.setRole('');
    this.setFullName('');
    this.setUserType('');
    this.setNguoiDungId('');

  }

  clearStoreage(): void {
    if (this.getSaveLogin()) {
      var key = atob(this.getKeyRemember());
      let reqtoken = {
        UserName: this.getUserName(),
        Password: key,
        UserLoginId: this.getUserId(),
        UserId: this.getAccountId()
      }
      this.http.post<any>(AppSettings.API_ADDRESS + Api.RESTORETOKEN, reqtoken).subscribe((response) => {
        if (response.status == 5) {
          this.clearStoreageAll();
          this._router.navigate(['/login']);
        } else {
          this.setAccessToken(response.token);
          this.setPermissionCategories(response.listCategory);
          this.setFunctions(response.listFunction);
          this.setPermissions(response.listPermission);
          var ReturnlUrl = this.getReturnUrl();
          if (ReturnlUrl != undefined && ReturnlUrl != '' && ReturnlUrl != null && ReturnlUrl.includes("business")) {
            this._router.navigateByUrl(ReturnlUrl);
          } else {
            this._router.navigate(['/business']);
          }
        }
      });
    } else { 
      this.clearStoreageAll();
      this._router.navigate(['/login']);
    } 
  }

  signOut() { 
    this.clearStoreageAll();
  }

  checkRoleChuyenVien() {
    let r = this.getRole();
    if (r == "0")
      return true;
    return false;
  }

  convertTextSeo(data : string)
  {
    // data = data.replace(/\ấ/g, "a");
    // data = data.replace(/\ầ/g, "a");
    // data = data.replace(/\ẩ/g, "a");
    // data = data.replace(/\ẫ/g, "a");
    // data = data.replace(/\ậ/g, "a");
    // //---------------------------------A^
    // data = data.replace(/\Ấ/g, "A");
    // data = data.replace(/\Ầ/g, "A");
    // data = data.replace(/\Ẩ/g, "A");
    // data = data.replace(/\Ẫ/g, "A");
    // data = data.replace(/\Ậ/g, "A");
    // //---------------------------------a(
    // data = data.replace(/\ắ/g, "a");
    // data = data.replace(/\ằ/g, "a");
    // data = data.replace(/\ẳ/g, "a");
    // data = data.replace(/\ẵ/g, "a");
    // data = data.replace(/\ặ/g, "a");
    // //---------------------------------A(
    // data = data.replace(/\Ắ/g, "A");
    // data = data.replace(/\Ằ/g, "A");
    // data = data.replace(/\Ẳ/g, "A");
    // data = data.replace(/\Ẵ/g, "A");
    // data = data.replace(/\Ặ/g, "A");
    // //--------------------------------a
    // data = data.replace(/\á/g,"a");
    // data = data.replace(/\à/g,"a");
    // data = data.replace(/\ả/g,"a");
    // data = data.replace(/\ã/g,"a");
    // data = data.replace(/\ạ/g,"a");
    // data = data.replace(/\â/g,"a");
    // data = data.replace(/\ă/g,"a");
    // //------------------/\-/g,----A
    // data = data.replace(/\Á/g,"A");
    // data = data.replace(/\À/g,"A");
    // data = data.replace(/\Ả/g,"A");
    // data = data.replace(/\Ã/g,"A");
    // data = data.replace(/\Ạ/g,"A");
    // data = data.replace(/\Â/g,"A");
    // data = data.replace(/\Ă/g,"A");
    // //------------------/\-/g,---e^
    // data = data.replace(/\ế/g,"e");
    // data = data.replace(/\ề/g,"e");
    // data = data.replace(/\ể/g,"e");
    // data = data.replace(/\ễ/g,"e");
    // data = data.replace(/\ệ/g,"e");
    // //------------------/\-/g,---E^
    // data = data.replace(/\Ế/g,"E");
    // data = data.replace(/\Ề/g,"E");
    // data = data.replace(/\Ể/g,"E");
    // data = data.replace(/\Ễ/g,"E");
    // data = data.replace(/\Ệ/g,"E");
    // //------------------/\-/g,----e
    // data = data.replace(/\é/g,"e");
    // data = data.replace(/\è/g,"e");
    // data = data.replace(/\ẻ/g,"e");
    // data = data.replace(/\ẽ/g,"e");
    // data = data.replace(/\ẹ/g,"e");
    // data = data.replace(/\ê/g,"e");
    // //------------------/\-/g,----E
    // data = data.replace(/\É/g,"E");
    // data = data.replace(/\È/g,"E");
    // data = data.replace(/\Ẻ/g,"E");
    // data = data.replace(/\Ẽ/g,"E");
    // data = data.replace(/\Ẹ/g,"E");
    // data = data.replace(/\Ê/g,"E");
    // //------------------/\-/g,----i
    // data = data.replace(/\í/g,"i");
    // data = data.replace(/\ì/g,"i");
    // data = data.replace(/\ỉ/g,"i");
    // data = data.replace(/\ĩ/g,"i");
    // data = data.replace(/\ị/g,"i");
    // //------------------/\-/g,----I
    // data = data.replace(/\Í/g,"I");
    // data = data.replace(/\Ì/g,"I");
    // data = data.replace(/\Ỉ/g,"I");
    // data = data.replace(/\Ĩ/g,"I");
    // data = data.replace(/\Ị/g,"I");
    //  //------------------/\-/g,---o^
    // data = data.replace(/\ố/g,"o");
    // data = data.replace(/\ồ/g,"o");
    // data = data.replace(/\ổ/g,"o");
    // data = data.replace(/\ỗ/g,"o");
    // data = data.replace(/\ộ/g,"o");
    //  //------------------/\-/g,---O^
    // data = data.replace(/\Ố/g,"O");
    // data = data.replace(/\Ồ/g,"O");
    // data = data.replace(/\Ổ/g,"O");
    // data = data.replace(/\Ô/g,"O");
    // data = data.replace(/\Ộ/g,"O");
    //  //------------------/\-/g,---o*
    // data = data.replace(/\ớ/g,"o");
    // data = data.replace(/\ờ/g,"o");
    // data = data.replace(/\ở/g,"o");
    // data = data.replace(/\ỡ/g,"o");
    // data = data.replace(/\ợ/g,"o");
    //  //------------------/\-/g,---O*
    //  data = data.replace(/\Ớ/g,"O");
    // data = data.replace(/\Ờ/g,"O");
    // data = data.replace(/\Ở/g,"O");
    // data = data.replace(/\Ỡ/g,"O");
    // data = data.replace(/\Ợ/g,"O");
    // //------------------/\-/g,---u*
    // data = data.replace(/\ứ/g,"u");
    // data = data.replace(/\ừ/g,"u");
    // data = data.replace(/\ử/g,"u");
    // data = data.replace(/\ữ/g,"u");
    //  data = data.replace(/\ự/g,"u");
    //  //------------------/\-/g,---U*
    //  data = data.replace(/\Ứ/g,"U");
    //  data = data.replace(/\Ừ/g,"U");
    //  data = data.replace(/\Ử/g,"U");
    //  data = data.replace(/\Ữ/g,"U");
    //  data = data.replace(/\Ự/g,"U");
    //  //------------------/\-/g,----y
    //  data = data.replace(/\ý/g,"y");
    //  data = data.replace(/\ỳ/g,"y");
    //  data = data.replace(/\ỷ/g,"y");
    //  data = data.replace(/\ỹ/g,"y");
    //  data = data.replace(/\ỵ/g,"y");
    //  //------------------/\-/g,----Y
    //  data = data.replace(/\Ý/g,"Y");
    //  data = data.replace(/\Ỳ/g,"Y");
    // data = data.replace(/\Ỷ/g,"Y");
    //  data = data.replace(/\Ỹ/g,"Y");
    //  data = data.replace(/\Ỵ/g,"Y");
    // //------------------/\-/g,---DD
    // data = data.replace(/\Đ/g,"D");
    // data = data.replace(/\Đ/g,"D");
    // data = data.replace(/\đ/g,"d");
    // //------------------/\-/g,----o
    // data = data.replace(/\ó/g,"o");
    // data = data.replace(/\ò/g,"o");
    // data = data.replace(/\ỏ/g,"o");
    // data = data.replace(/\õ/g,"o");
    // data = data.replace(/\ọ/g,"o");
    // data = data.replace(/\ô/g,"o");
    // data = data.replace(/\ơ/g,"o");
    //  //------------------/\-/g,----O
    // data = data.replace(/\Ó/g,"O");
    // data = data.replace(/\Ò/g,"O");
    // data = data.replace(/\Ỏ/g,"O");
    // data = data.replace(/\Õ/g,"O");
    //  data = data.replace(/\Ọ/g,"O");
    // data = data.replace(/\Ô/g,"O");
    //  data = data.replace(/\Ơ/g,"O");
    //  //------------------/\-/g,----u
    // data = data.replace(/\ú/g,"u");
    //  data = data.replace(/\ù/g,"u");
    //  data = data.replace(/\ủ/g,"u");
    //  data = data.replace(/\ũ/g,"u");
    //  data = data.replace(/\ụ/g,"u");
    // data = data.replace(/\ư/g,"u");
    // //------------------/\-/g,----U
    // data = data.replace(/\Ú/g,"U");
    // data = data.replace(/\Ù/g,"U");
    // data = data.replace(/\Ủ/g,"U");
    // data = data.replace(/\Ũ/g,"U");
    // data = data.replace(/\Ụ/g,"U"); 
    // data = data.replace(/\Ư/g,"U");
    
    // //---------------------------------U
    // data = data.replace(/\./g, "-");
    // data = data.replace(/\,/g, "-");
    // data = data.replace(/\:/g, "-");
    // data = data.replace(/\;/g, "-");
    // data = data.replace(/\ /g, "-");
    // data = data.replace(/\'/g, "");
    // //
    // data = data.replace("--", "-");

    data = data.toLowerCase();
    //     We can also use this instead of from line 11 to line 17
    //     str = str.replace(/\u00E0|\u00E1|\u1EA1|\u1EA3|\u00E3|\u00E2|\u1EA7|\u1EA5|\u1EAD|\u1EA9|\u1EAB|\u0103|\u1EB1|\u1EAF|\u1EB7|\u1EB3|\u1EB5/g, "a");
    //     str = str.replace(/\u00E8|\u00E9|\u1EB9|\u1EBB|\u1EBD|\u00EA|\u1EC1|\u1EBF|\u1EC7|\u1EC3|\u1EC5/g, "e");
    //     str = str.replace(/\u00EC|\u00ED|\u1ECB|\u1EC9|\u0129/g, "i");
    //     str = str.replace(/\u00F2|\u00F3|\u1ECD|\u1ECF|\u00F5|\u00F4|\u1ED3|\u1ED1|\u1ED9|\u1ED5|\u1ED7|\u01A1|\u1EDD|\u1EDB|\u1EE3|\u1EDF|\u1EE1/g, "o");
    //     str = str.replace(/\u00F9|\u00FA|\u1EE5|\u1EE7|\u0169|\u01B0|\u1EEB|\u1EE9|\u1EF1|\u1EED|\u1EEF/g, "u");
    //     str = str.replace(/\u1EF3|\u00FD|\u1EF5|\u1EF7|\u1EF9/g, "y");
    //     str = str.replace(/\u0111/g, "d");
    data = data.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    data = data.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    data = data.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    data = data.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    data = data.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    data = data.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    data = data.replace(/đ/g, "d");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    data = data.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng 
    data = data.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
    data = data.replace(/\./g, "-");
    data = data.replace(/\,/g, "-");
    data = data.replace(/\:/g, "-");
    data = data.replace(/\;/g, "-");
    data = data.replace(/\ /g, "-");
    data = data.replace(/\'/g, "");
    data = data.replace("--", "-");
    return data;

  }

  convertTextSeo1(data : string)
  {
    data = data.replace(/\ấ/gi, "a");
    data = data.replace(/\ầ/gi, "a");
    data = data.replace(/\ẩ/gi, "a");
    data = data.replace(/\ẫ/gi, "a");
    data = data.replace(/\ậ/gi, "a");
    //---------------------------------A^
    data = data.replace(/\Ấ/gi, "A");
    data = data.replace(/\Ầ/gi, "A");
    data = data.replace(/\Ẩ/gi, "A");
    data = data.replace(/\Ẫ/gi, "A");
    data = data.replace(/\Ậ/gi, "A");
    //---------------------------------a(
    data = data.replace(/\ắ/gi, "a");
    data = data.replace(/\ằ/gi, "a");
    data = data.replace(/\ẳ/gi, "a");
    data = data.replace(/\ẵ/gi, "a");
    data = data.replace(/\ặ/gi, "a");
    //---------------------------------A(
    data = data.replace(/\Ắ/gi, "A");
    data = data.replace(/\Ằ/gi, "A");
    data = data.replace(/\Ẳ/gi, "A");
    data = data.replace(/\Ẵ/gi, "A");
    data = data.replace(/\Ặ/gi, "A");
    //---------------------------------a
    data = data.replace(/\á/gi,"a");
    data = data.replace(/\à/gi,"a");
    data = data.replace(/\ả/gi,"a");
    data = data.replace(/\ã/gi,"a");
    data = data.replace(/\ạ/gi,"a");
    data = data.replace(/\â/gi,"a");
    data = data.replace(/\ă/gi,"a");
    //------------------/\-/gi,----A
    data = data.replace(/\Á/gi,"A");
    data = data.replace(/\À/gi,"A");
    data = data.replace(/\Ả/gi,"A");
    data = data.replace(/\Ã/gi,"A");
    data = data.replace(/\Ạ/gi,"A");
    data = data.replace(/\Â/gi,"A");
    data = data.replace(/\Ă/gi,"A");
    //------------------/\-/gi,---e^
    data = data.replace(/\ế/gi,"e");
    data = data.replace(/\ề/gi,"e");
    data = data.replace(/\ể/gi,"e");
    data = data.replace(/\ễ/gi,"e");
    data = data.replace(/\ệ/gi,"e");
    //------------------/\-/gi,---E^
    data = data.replace(/\Ế/gi,"E");
    data = data.replace(/\Ề/gi,"E");
    data = data.replace(/\Ể/gi,"E");
    data = data.replace(/\Ễ/gi,"E");
    data = data.replace(/\Ệ/gi,"E");
    //------------------/\-/gi,----e
    data = data.replace(/\é/gi,"e");
    data = data.replace(/\è/gi,"e");
    data = data.replace(/\ẻ/gi,"e");
    data = data.replace(/\ẽ/gi,"e");
    data = data.replace(/\ẹ/gi,"e");
    data = data.replace(/\ê/gi,"e");
    //------------------/\-/gi,----E
    data = data.replace(/\É/gi,"E");
    data = data.replace(/\È/gi,"E");
    data = data.replace(/\Ẻ/gi,"E");
    data = data.replace(/\Ẽ/gi,"E");
    data = data.replace(/\Ẹ/gi,"E");
    data = data.replace(/\Ê/gi,"E");
    //------------------/\-/gi,----i
    data = data.replace(/\í/gi,"i");
    data = data.replace(/\ì/gi,"i");
    data = data.replace(/\ỉ/gi,"i");
    data = data.replace(/\ĩ/gi,"i");
    data = data.replace(/\ị/gi,"i");
    //------------------/\-/gi,----I
    data = data.replace(/\Í/gi,"I");
    data = data.replace(/\Ì/gi,"I");
    data = data.replace(/\Ỉ/gi,"I");
    data = data.replace(/\Ĩ/gi,"I");
    data = data.replace(/\Ị/gi,"I");
     //------------------/\-/gi,---o^
    data = data.replace(/\ố/gi,"o");
    data = data.replace(/\ồ/gi,"o");
    data = data.replace(/\ổ/gi,"o");
    data = data.replace(/\ỗ/gi,"o");
    data = data.replace(/\ộ/gi,"o");
     //------------------/\-/gi,---O^
    data = data.replace(/\Ố/gi,"O");
    data = data.replace(/\Ồ/gi,"O");
    data = data.replace(/\Ổ/gi,"O");
    data = data.replace(/\Ô/gi,"O");
    data = data.replace(/\Ộ/gi,"O");
     //------------------/\-/gi,---o*
    data = data.replace(/\ớ/gi,"o");
    data = data.replace(/\ờ/gi,"o");
    data = data.replace(/\ở/gi,"o");
    data = data.replace(/\ỡ/gi,"o");
    data = data.replace(/\ợ/gi,"o");
     //------------------/\-/gi,---O*
     data = data.replace(/\Ớ/gi,"O");
    data = data.replace(/\Ờ/gi,"O");
    data = data.replace(/\Ở/gi,"O");
    data = data.replace(/\Ỡ/gi,"O");
    data = data.replace(/\Ợ/gi,"O");
    //------------------/\-/gi,---u*
    data = data.replace(/\ứ/gi,"u");
    data = data.replace(/\ừ/gi,"u");
    data = data.replace(/\ử/gi,"u");
    data = data.replace(/\ữ/gi,"u");
     data = data.replace(/\ự/gi,"u");
     //------------------/\-/gi,---U*
     data = data.replace(/\Ứ/gi,"U");
     data = data.replace(/\Ừ/gi,"U");
     data = data.replace(/\Ử/gi,"U");
     data = data.replace(/\Ữ/gi,"U");
     data = data.replace(/\Ự/gi,"U");
     //------------------/\-/gi,----y
     data = data.replace(/\ý/gi,"y");
     data = data.replace(/\ỳ/gi,"y");
     data = data.replace(/\ỷ/gi,"y");
     data = data.replace(/\ỹ/gi,"y");
     data = data.replace(/\ỵ/gi,"y");
     //------------------/\-/gi,----Y
     data = data.replace(/\Ý/gi,"Y");
     data = data.replace(/\Ỳ/gi,"Y");
    data = data.replace(/\Ỷ/gi,"Y");
     data = data.replace(/\Ỹ/gi,"Y");
     data = data.replace(/\Ỵ/gi,"Y");
    //------------------/\-/gi,---DD
    data = data.replace(/\Đ/gi,"D");
    data = data.replace(/\Đ/gi,"D");
    data = data.replace(/\đ/gi,"d");
    //------------------/\-/gi,----o
    data = data.replace(/\ó/gi,"o");
    data = data.replace(/\ò/gi,"o");
    data = data.replace(/\ỏ/gi,"o");
    data = data.replace(/\õ/gi,"o");
    data = data.replace(/\ọ/gi,"o");
    data = data.replace(/\ô/gi,"o");
    data = data.replace(/\ơ/gi,"o");
     //------------------/\-/gi,----O
    data = data.replace(/\Ó/gi,"O");
    data = data.replace(/\Ò/gi,"O");
    data = data.replace(/\Ỏ/gi,"O");
    data = data.replace(/\Õ/gi,"O");
     data = data.replace(/\Ọ/gi,"O");
    data = data.replace(/\Ô/gi,"O");
     data = data.replace(/\Ơ/gi,"O");
     //------------------/\-/gi,----u
    data = data.replace(/\ú/gi,"u");
     data = data.replace(/\ù/gi,"u");
     data = data.replace(/\ủ/gi,"u");
     data = data.replace(/\ũ/gi,"u");
     data = data.replace(/\ụ/gi,"u");
    data = data.replace(/\ư/gi,"u");
    //------------------/\-/gi,----U
    data = data.replace(/\Ú/gi,"U");
    data = data.replace(/\Ù/gi,"U");
    data = data.replace(/\Ủ/gi,"U");
    data = data.replace(/\Ũ/gi,"U");
    data = data.replace(/\Ụ/gi,"U"); 
    data = data.replace(/\Ư/gi,"U");
    
    //---------------------------------U
    data = data.replace(/\./gi, "-");
    data = data.replace(/\,/gi, "-");
    data = data.replace(/\:/gi, "-");
    data = data.replace(/\;/gi, "-");
    data = data.replace(/\ /gi, "-");
    data = data.replace(/\'/gi, "");
    //
    data = data.replace("--", "-");

    return data;

  }
  
}

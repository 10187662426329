
<div class="panel panel-default modal-dialog no-border" style="margin: 0!important; width: 500px!important; max-width: 80vw;">
  <div class="panel-heading" style="background-color: #337ab7; border-color: #337ab7; color: #fff;">
    <button type="button" class="close btn-close-modal pull-right" mat-dialog-close tabindex="8" style="font-size: 24px !important; margin-top: -3px; opacity: 0.9; font-weight: 400; color: #fff;">
      ×
    </button>
    <p class="title-ct uppercase no-margin">Xác nhận</p>
  </div>
  <div class="modal-body">
    <span>
      Anh/chị có chắc chắn thực hiện hành động này?
    </span>
  </div>
  <div class="modal-footer">
    <button md-raised-button [mat-dialog-close]="true" cdkFocusInitial class="btn btn-success btn-sm btn-confirm">Đồng ý</button>
    <button md-raised-button mat-dialog-close class="btn btn-default btn-sm btn-confirm">Hủy</button>
  </div>
</div>

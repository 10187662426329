export class AppSettings {
 // public static HostingAddress = "http://10.16.66.10";
 // public static API_ADDRESS = "http://localhost/MyAPI.Api/api/";

  //public static MobileHostingAddress = "http://localhost:4200";

 public static API_ADDRESS = "https://api.grabhouse.vn/api/";



   public static Files_ADDRESS = "https://viewfile.grabhouse.vn/";
   public static HostingAddress = "https://grabhouse.vn";
   public static HostingAddressNotSecure = "http://grabhouse.vn";
   public static MobileHostingAddress = "https://m.grabhouse.vn";

   public static ShareHostingAddress = "https://m.grabhouse.vn";

   public static API_DOWNLOADFILES = "https://api.grabhouse.vn/";
   public static VIEW_FILE = "http://10.16.66.10/DocViewer?fileurl=";
   public static API_ADDRESS1 = "https://api.grabhouse.vn";

   //public static PassWord = "Ab@"
  
}

<div class="limiter container-login100">
  <div class="col-md-7 bg-login"></div>
  <div class="col-md-5 col-sm-6 flex-box-login">
    <div class="login-container">
      <div class="col-md-12">
        <div class="p-t-70 p-l-20 p-r-20 padding-xs">
          <h5 class="txt-dangnhap">Lấy lại mật khẩu</h5>
          <div class="wrap-login100 p-t-30 p-b-30" style="border: inherit">
            <form class="login100-form validate-form flex-sb flex-w" [formGroup]="forgetPasswordForm" #formDir="ngForm" (ngSubmit)="forgetPassword()">
              <p><span>Nhập email bạn đã đăng ký để lấy lại mật khẩu. Chúng tôi sẽ gửi mật khẩu mới cho bạn qua email</span></p>
              <div class="wrap-input100 validate-input m-b-16" [ngClass]="{ 'has-error': (submitted && f.email.errors) ||(f.email.invalid && (f.email.dirty || f.email.touched)) }">
                <input class="input100" type="email" id="email" placeholder="Nhập email" formControlName="email" [(ngModel)]="user.Email" required appAutofocus>
                <span class="focus-input100"></span>
              </div>
              <div *ngIf="(submitted && f.email.invalid)||(f.email.invalid && (f.email.dirty || f.email.touched))"
                   class="alert has-error m-b-12">
                <div *ngIf="f.email.errors.required">
                  <span class="help-block">Trường này không được để trống</span>
                </div>
              </div>
              <div class="container-login100-form-btn m-t-17">
                <button class="login100-form-btn" type="submit">
                  Gửi yêu cầu
                </button>
              </div>
              <div class="w-full text-center" style="padding-top: 20px">
                <div>
                  Quay lại trang
                  <button (click)="login()" class="txt1">
                    <span>đăng nhập</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import {
  NgForm,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpEventType,
  HttpRequest,
} from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "../../../../services/authentication.service";
import { UserInfoStorageService } from "../../../../commons/user-info-storage.service";
import { LoginUserObject } from "../../../../models/loginUserObject";
import { ToastrService } from "ngx-toastr";
import { DataService } from "../../../business-management/sharedata.service";
import { AppSettings } from "src/app/constants/app-setting";
import { Api } from "src/app/constants/api";
import { CaptchaService } from "src/app/services/captcha.service";
import { environment } from '../../../../../environments/environment';
import { DeviceDetectorService  } from 'ngx-device-detector';

declare var jquery: any;
declare var $: any;
//function refresh() {
//  setTimeout(function () {
//    location.reload()
//  });
//}

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  user: LoginUserObject = {
    UserName: "",
    Password: "",
  };
  isAbleCaptcha: any = "";
  isSaveLogin: boolean = false;
  
  loginForm: FormGroup;
  lang: any = "vi";
  theme: any = "light";
  size: any = "normal";
  type: any = "image";
  siteKey: any = "6LdcUHgUAAAAAOoPO7q4P2s4YFU2N3khp4DBf3Dh";
  captchaCode: string = null;
  submitted: boolean = false;
  configcaptcha: any = {
    theme: this.theme,
    type: this.type,
    size: this.size,
    tabindex: 3,
  };

  captchaImage: any = "";
  thietBi :string ="";
  os: string= "";
  trinhDuyet : string= "";
  isDesktop: boolean = false;
  isMobile: boolean = false;
  isTablet : boolean = false;
  iPAddress : string = "";
  constructor(
    private _fb: FormBuilder,
    private _avRoute: ActivatedRoute,
    private authenService: AuthenticationService,
    private _router: Router,
    private storeageService: UserInfoStorageService,
    private toastr: ToastrService,
    private http: HttpClient,
    private shareData: DataService,
    private captChaService: CaptchaService,
    private deviceService: DeviceDetectorService
  ) {

    //this.epicFunction();
    this.loginForm = new FormGroup({
      name: new FormControl(this.user.UserName, [Validators.required]),
      pass: new FormControl(this.user.Password, [Validators.required]),
      captcha: new FormControl(this.captchaCode, [
        // Validators.required
      ]),
      isRemember: new FormControl(this.isSaveLogin, []),
    });
    if (this.storeageService.getAccessToken()) {
      var ReturnlUrl = this.storeageService.getReturnUrl();
      if (
        ReturnlUrl != undefined &&
        ReturnlUrl != "" &&
        ReturnlUrl != null &&
        ReturnlUrl.includes("business")
      ) {
        this._router.navigateByUrl(ReturnlUrl);
      } else {
        this._router.navigate(["/business"]);
      }
      return;
    }
  }
  
  epicFunction() {
    

    this.http.get<{ ip: string }>("https://jsonip.com/")
        .subscribe((data) => {
          
           if (data != null) {
          this.iPAddress=data.ip;
          }
    });

    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();

    if(this.isDesktop)
    {
      this.thietBi = "Desktop";
    }
    if(this.isMobile)
    {
      this.thietBi = "Mobile";
    }
    if(this.isTablet)
    {
      this.thietBi = "Tablet";
    }
    //this.thietBi = this.deviceService.getDeviceInfo().device;
    this.os  = this.deviceService.getDeviceInfo().os + '/' + this.deviceService.getDeviceInfo().os_version;
    this.trinhDuyet = this.deviceService.getDeviceInfo().browser;
  }


  ngOnInit(): void {
    //var currentlink = location.toString();

    // if(this.isMobile)
    // {
    //   if(currentlink.includes("login"))
    //   {
    //     return;
    //   }
    //   else
    //   {
    //     this._router.navigate(['/mobile/trang-chu']);
    //     return;
    //   }
    // }

    this.submitted = false;
    this.reloadImage();
  }

  login() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      if (this.loginForm.controls.name.status == "INVALID") {
        $("#name").focus();
        return;
      }
      if (this.loginForm.controls.pass.status == "INVALID") {
        $("#pass").focus();
        return;
      }

      return;
    } else {
      var constdata = {
        CaptchaCode: this.captchaCode,
      };
      this.captChaService.send(constdata).subscribe((result) => {
        if (result.status == 1) {
          var model = {
            Userlg: this.user,
            ThietBi: this.thietBi,
            Os : this.os,
            TrinhDuyet : this.trinhDuyet,
            IpAddress: this.iPAddress
          };
          this.authenService.login(model).subscribe(
            (data) => {
              if (data.status === 1) {
                this.shareData.setIsLogin(true);
                this.storeageService.setUserName(data.userName);
                this.storeageService.setAccessToken(data.accessToken);
                this.storeageService.setUserId(data.userId);
                this.storeageService.setIsSuperAdmin(data.isSuperAdmin);
                this.storeageService.setDeparmentId(data.deparmentId);
                this.storeageService.setUnitId(data.unitId);
                this.storeageService.setAccountId(data.accountId);
                this.storeageService.setPermissionCategories(
                  data.permissionCategories
                );
                this.storeageService.setFunctions(data.functions);
                this.storeageService.setPermissions(data.permissions);
                this.storeageService.setUnitName(data.unitName);
                this.storeageService.setSaveLogin(this.isSaveLogin);
                this.storeageService.setIsHaveToken(data.isHaveToken);
                this.storeageService.setRole(data.role);
                this.storeageService.setFullName(data.fullName);
                localStorage.setItem("anhDaiDien", data.anhDaiDien);
                this.storeageService.setUserType(data.userType);
                this.storeageService.setNguoiDungId(data.nguoiDungId);


                if (this.isSaveLogin) {
                  this.storeageService.setKeyRemember(btoa(this.user.Password));
                } else {
                  this.storeageService.setKeyRemember("");
                }
                var returnlURL = this.storeageService.getReturnUrl();
                environment.olderbacklink = "";
                
                if (returnlURL != undefined && returnlURL != '' && returnlURL != null 
                   && returnlURL != '/' && returnlURL != 'undefined' &&  returnlURL.includes("business")) {
                  this._router.navigate([returnlURL]);
                } else {
                    //cms
                    this._router.navigate(['/business']);
                    //portal
                    //this._router.navigate(['/trang-chu']);


                    // if(this.isMobile)
                    // {
                    //     this._router.navigate(['/mobile/trang-chu']);
                    // }
                    // else
                    // {
                    //  this._router.navigate(['/trang-chu']);
                    //}
                }
    
                this.http
                  .get<{ ip: string }>("https://jsonip.com/")
                  .subscribe((data) => {
                    if (data != null) {
                      this.storeageService.setIpAddress(data.ip);
                    }
                  });
                //this._router.navigate(["/business"]);
              } else if (data.status === 2) {
                this.reloadImage();
                this.captchaCode = "";
                this.toastr.error(data.message, "Đăng nhập thất bại");
              }
            },
            (error) => {
              console.error(error);
              alert(error);
            }
          );
        } else {
          this.toastr.warning("Vui lòng nhập lại mã captcha");
          this.reloadImage();
          this.captchaCode = "";
          //   this.captchaEl.nativeElement.focus();
        }
      });
    }
  }

  get name() {
    return this.loginForm.get("name");
  }
  get pass() {
    return this.loginForm.get("pass");
  }
  get captcha() {
    return this.loginForm.get("captcha");
  }

  forgetPassWord(): void {
    this._router.navigate(["/forgotPass"]);
  }

  handleExpire() {
    console.log("Hết hạn");
  }
  handleLoad() {
    console.log("đang load");
  }
  handleSuccess(data) {
    this.isAbleCaptcha = data;
  }

  reloadImage() {
    this.captchaImage =
      AppSettings.API_ADDRESS +
      Api.getImageCaptcha +
      "?" +
      Math.random() * 100000000000000000000;
  }
}

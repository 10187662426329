import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  
  { path: "", redirectTo: "trang-chu", pathMatch: "full" },
  {
    path: "",
    loadChildren: "./home-page/home-page.module#HomePageModule",
  },
  // {
  //   path: "",
  //   loadChildren: "./home-page-mobile/home-page-mobile.module#HomePageMobileModule", 
  // }, 
  {
    path: "login",
    loadChildren: "./modules/logins/logins.module#LoginsModule",
  },
  {
    path: "business",
    loadChildren: "./modules/business.module#BusinessModule",
  },
 
  // {
  //   path: "home",
  //   loadChildren: "./home-page/home-page.module#HomePageModule",
  // },
  // {
  //   path: "cong-bo",
  //   loadChildren: "./home-page/home-page.module#HomePageModule",
  // },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  declarations: [],
  exports: [RouterModule],
})
export class AppRoutingModule {}
